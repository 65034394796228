
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PagesEditorSaveButton extends Vue {
  @Prop({ required: true })
  private readonly isLoading: boolean;

  @Prop({ required: true })
  private readonly isNewPage: boolean;
}
