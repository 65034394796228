
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Chrome as ColorPicker } from 'vue-color';
import Button from '@/components/help/button.vue';

@Component({
  components: {
    ColorPicker,
    Button,
  },
})
export default class PagesEditorColor extends Vue {
  @Prop({ required: true })
  private readonly defaultValue: string;

  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: false, default: false })
  private readonly disabled: boolean;

  private isDropdownOpened = false;

  private inputValue: string | null = null;

  private color = '';

  private updateColor(value: { hex: string }) {
    this.inputValue = value.hex;
  }

  toggleDropdown() {
    const value = !this.isDropdownOpened;
    this.isDropdownOpened = value;
  }

  closeDropdown() {
    this.isDropdownOpened = false;
  }

  private matchHexRgb(value: string) {
    return value.match(/^#([a-f0-9]{3}|[a-f0-9]{6})$/gis);
  }

  mounted() {
    this.inputValue = this.defaultValue;
    this.color = this.defaultValue;
  }

  @Watch('color')
  @Watch('inputValue')
  private onInputValueChange(value: string) {
    if (this.matchHexRgb(value)) {
      this.color = value;
    } else {
      this.color = '#008EFF';
    }
    this.$emit('changeColor', this.color);
  }

  onMousedown(e: Event) {
    if (this.isDropdownOpened) {
      e.stopPropagation();
    }
  }
}
