
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsHeader from '@/stores/forms/header';
import useFormsPages from '@/stores/forms/pages';
import { ErrorResponse, Page, Settings } from './types';
import PagePreview from './pages-editor/pages-editor-preview.vue';
import PageSettings from './pages-editor/pages-editor-settings.vue';
import PagesEditorSaveButton from './pages-editor/pages-editor-save-button.vue';
import { Action } from '../components/table/types';
import ToolbarActions from '../components/table/toolbar-actions.vue';
import { formsUrl } from '@/utils/url-manager.js';

const defaultSettings: Settings = {
  bodyBackground: '#faf9ff',
  bodyImage: null,
  footerBackground: '#f4f3fb',
  footerText: `Copyright © ${new Date().getFullYear()}`,
  footerTextColor: '#797879',
  headerBackground: '#008eff',
  headerPattern: `${formsUrl}images/patterns/p014.png`,
  logoImage: `${formsUrl}images/white-logo64.png`,
  logoLink: '',
  pageTitle: '',
  textAbove: '<h1 style="text-align: center;">Please fill out the form</h1>',
};

@Component({
  name: 'PagesEditor',
  components: {
    PagePreview,
    PageSettings,
    PagesEditorSaveButton,
    ToolbarActions,
    'share-form': () => import('./pages-share-modal.vue'),
  },
  computed: {
    ...mapState(useFormsPages, ['pages']),
  },
})
export default class PageEditor extends Vue {
  @Prop({ required: false, default: '' })
  private readonly pageId: string;

  $nprogress: any;

  private isDefault = false;

  private settings: Settings | null = null;

  private validatedSettings: Partial<Settings> = {};

  private isLoading = false;

  private showSaveAsNew = false;

  private formsHeader = useFormsHeader();

  private formsPages = useFormsPages();

  get isNewPage() {
    return this.pageId.toLowerCase() === 'new';
  }

  get actions(): Action[] {
    return [
      {
        id: '0',
        icon: ['fal', 'share-from-square'],
        title: 'Share',
        handler: this.showShareModal,
        visible: true,
        disabled: this.isLoading,
      },
      {
        id: '1',
        icon: ['fal', 'file-check'],
        title: 'Set default',
        handler: this.setDefaultPage,
        visible: !this.isDefault,
        disabled: this.isLoading,
      },
      {
        id: '2',
        icon: ['fal', 'trash-can'],
        title: 'Delete',
        handler: this.removePage,
        visible: true,
        disabled: this.isLoading,
      },
    ];
  }

  private async save() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    try {
      if (this.isNewPage) {
        await this.saveNew();
      } else {
        await this.saveChanges();
      }
    } catch (error) {
      const { status, data } = (error as ErrorResponse)?.response;
      if (status === 409) {
        this.validatedSettings = { pageTitle: data.error };
      } else {
        console.error(error);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async saveNew() {
    try {
      const id = (await this.formsPages.createPage({
        title: this.settings?.pageTitle,
        settings: this.settings,
      } as Page)) as string;

      this.$router.push({ name: 'Forms_Pages_Editor', params: { pageId: id } });
    } catch (error) {
      const { status, data } = (error as ErrorResponse)?.response;
      if (status === 409) {
        this.validatedSettings = { pageTitle: data.error };
      } else {
        console.error(error);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async saveChanges() {
    const data = {
      id: this.pageId,
      title: this.settings?.pageTitle,
      isDefault: this.isDefault,
      settings: this.settings,
    } as Page;

    await this.formsPages.updatePage(data);

    this.setPageSettings(data);
  }

  private setPageSettings(data: Page) {
    this.settings = { ...data.settings, pageTitle: data.title };
    this.formsHeader.pageTitle = data.title;
    this.formsHeader.setPageBreadcrumb({ name: 'Forms_Pages_Editor', title: data.title });
  }

  private async saveAsNew() {
    this.hideSaveAsNew();

    if (!this.validate()) {
      return;
    }

    try {
      this.isLoading = true;

      const copyTitle = `${this.settings?.pageTitle} (Copy)`;

      const id = (await this.formsPages.createPage({
        title: copyTitle,
        isDefault: false,
        settings: this.settings,
      } as Page)) as string;

      this.$router.push({ name: 'Forms_Pages_Editor', params: { pageId: id } });
    } catch (error) {
      const { status, data } = (error as ErrorResponse)?.response;
      if (status === 409) {
        this.validatedSettings = { pageTitle: data.error };
      } else {
        console.error(error);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async removePage() {
    if (window.confirm('Are you sure you want to delete this page?')) {
      await this.formsPages.deletePage(this.pageId);

      this.$router.push({ name: 'Forms_Pages' });
    }
  }

  private validate() {
    const thisFieldIsRequired = 'This field is required';
    const validatedSettings: Partial<Settings> = {};

    validatedSettings.pageTitle = this.settings?.pageTitle ? null : thisFieldIsRequired;

    this.validatedSettings = validatedSettings;

    return !Object.values(validatedSettings).some((p) => Boolean(p));
  }

  private showShareModal() {
    this.$modal.show('share-form');
  }

  private hideShareModal() {
    this.$modal.hide('share-form');
  }

  private hideSaveAsNew() {
    this.showSaveAsNew = false;
  }

  private async setDefaultPage() {
    await this.formsPages.setDefaultPage(this.pageId);
    this.isDefault = true;
  }

  created() {
    window.addEventListener('click', this.hideSaveAsNew);
  }

  destroyed() {
    window.removeEventListener('click', this.hideSaveAsNew);
  }

  @Watch('pageId', { immediate: true })
  private async onPageIdChange() {
    if (this.isNewPage) {
      this.settings = defaultSettings;
      this.formsHeader.pageTitle = 'New form page';
      this.formsHeader.setPageBreadcrumb({ name: 'Forms_Pages_Editor', title: 'New form page' });
    } else {
      const data = await this.formsPages.getPageSettings(this.pageId);

      if (data) {
        this.isDefault = !!data.isDefault;
        this.setPageSettings(data);
      }
    }
  }
}
