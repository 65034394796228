
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import RichTextEditor from '@/components/rich-text-editor/index.vue';
import { rteConfig } from '../types';

@Component({
  components: {
    RichTextEditor,
  },
})
export default class PagesEditorRichText extends Vue {
  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: true })
  private readonly config: rteConfig;

  @Prop({ required: false, default: '' })
  private readonly defaultValue: string;

  private value = '';

  mounted() {
    this.value = this.defaultValue;
  }

  @Watch('value')
  private onValueChange(value: string) {
    this.$emit('change', value);
  }
}
