
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PagesEditorButton extends Vue {
  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: false, default: false })
  private readonly disabled: boolean;

  @Prop({ required: false, default: null })
  private readonly icon: string[];
}
