
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import vSelect from 'vue-select';
import OpenIndicator from './open-indicator.vue';
import 'vue-select/dist/vue-select.css';

@Component({
  components: {
    vSelect,
  },
})
export default class PagesEditorSelect extends Vue {
  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: true })
  private readonly defaultValue: any;

  @Prop({ required: true })
  private readonly options: any[];

  @Prop({ required: false, default: false })
  private readonly disabled: boolean;

  @Prop({ required: true })
  private readonly label: string;

  private value = null;

  private get components() {
    return {
      OpenIndicator,
    };
  }

  @Watch('defaultValue', { immediate: true })
  private ondefaultValueChange(value: any) {
    this.value = value;
  }

  @Watch('value')
  private onValueChange(value: any) {
    this.$emit('change', value);
  }
}
