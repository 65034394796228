
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Settings } from '../types';
import { formsDefaultCommonCssUrl } from '@/utils/url-manager.js';

@Component({
  name: 'PagePreview',
})
export default class PagesEditorPreview extends Vue {
  @Prop()
  private readonly settings: Settings;

  get headerBackgroundStyle() {
    const background = this.settings.headerBackground;
    const pattern = this.settings.headerPattern;

    return pattern
      ? `background: linear-gradient(to right, ${background}, ${background} 50%, transparent), url('${pattern}') repeat, ${background};`
      : `background: ${background};`;
  }

  get bodyBackgroundStyle() {
    const background = this.settings.bodyBackground;
    const image = this.settings.bodyImage;

    return image ? `background-image: url('${image}');` : `background-color: ${background};`;
  }

  get widgetCssUrl() {
    return formsDefaultCommonCssUrl;
  }
}
