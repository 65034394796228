
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator';

@Component
export default class PagesEditorText extends Vue {
  @Prop({ required: true })
  private readonly title: string;

  @Prop({ required: false, default: '' })
  private readonly defaultValue: string;

  private value = '';

  @Prop({ required: false, default: '' })
  private readonly placeholder: string;

  @Prop({ required: false, default: '' })
  private readonly validatedSettings: string;

  @Ref()
  private readonly pageEditorTextRef: HTMLInputElement;

  @Watch('defaultValue', { immediate: true })
  private onDefaultValueChange() {
    this.value = this.defaultValue;
  }

  @Watch('value')
  private onValueChange(value: string) {
    this.$emit('change', value);
  }

  @Watch('validatedSettings')
  private onValidated() {
    this.pageEditorTextRef.focus();
  }
}
