import { render, staticRenderFns } from "./pages-editor-text.vue?vue&type=template&id=5cf97c2f&scoped=true&"
import script from "./pages-editor-text.vue?vue&type=script&lang=ts&"
export * from "./pages-editor-text.vue?vue&type=script&lang=ts&"
import style0 from "./pages-editor-text.vue?vue&type=style&index=0&id=5cf97c2f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf97c2f",
  null
  
)

export default component.exports