
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import PagesEditorText from './pages-editor-text.vue';
import PagesEditorButton from './pages-editor-button.vue';
import PagesEditorColor from './pages-editor-color.vue';
import PagesEditorSelect from './pages-editor-select.vue';
import PagesEditorRichText from './pages-editor-rich-text.vue';
import { Settings, rteConfig, ImagePattern } from '../types';
import { formsDefaultCommonCssUrl, formsApiEndpoint } from '@/utils/url-manager.js';

@Component({
  components: {
    PagesEditorText,
    PagesEditorButton,
    PagesEditorColor,
    PagesEditorSelect,
    'pages-editor-image': () => import('./pages-editor-image.vue'),
    PagesEditorRichText,
  },
  name: 'PageSettings',
})
export default class PagesEditorSettings extends Vue {
  @Prop({ required: true })
  private readonly value: Settings;

  @Prop({ required: false, default: {} })
  private readonly validatedSettings: Settings;

  private headerPatternSelectValue: ImagePattern | null = null;

  private headerPatternOptions: ImagePattern[] = [];

  private rteConfig: rteConfig = {
    editSource: false,
    buttons: [
      'h1',
      'h2',
      'h3',
      'bold',
      'italic',
      'strike',
      'underline',
      'ul',
      'ol',
      'alignLeft',
      'alignCenter',
      'alignRight',
    ],
    minHeight: 150,
  };

  get settings() {
    return {
      bodyBackground: this.value.bodyBackground,
      bodyImage: this.value.bodyImage,
      footerBackground: this.value.footerBackground,
      footerText: this.value.footerText,
      footerTextColor: this.value.footerTextColor,
      headerBackground: this.value.headerBackground,
      headerPattern: this.value.headerPattern,
      logoImage: this.value.logoImage,
      logoLink: this.value.logoLink,
      pageTitle: this.value.pageTitle,
      textAbove: this.value.textAbove,
    };
  }

  get widgetCssUrl() {
    return formsDefaultCommonCssUrl;
  }

  async created() {
    const response = await axios.get<ImagePattern[]>(
      `${formsApiEndpoint}pages/pattern-images`,
    );
    this.headerPatternOptions = [{ name: 'No Pattern', downloadUrl: null }, ...response.data];

    const getPatternName = (patternUrl: string | null) => /[\w.]+$/m.exec(patternUrl || '')?.[0];

    const [pattern] = this.headerPatternOptions.filter(
      (p) => getPatternName(p.downloadUrl) === getPatternName(this.value.headerPattern),
    );

    this.headerPatternSelectValue = pattern ?? null;
  }

  changeValue(key: string, value: any) {
    this.$emit('input', { ...this.settings, [key]: value });
  }
}
